import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  isProduction = false;

  constructor() {
    this.isProduction = environment.production;
  }

  addGAScripts(googleAnalyticsCode, oldGoogleAnalyticsCode) {
    if (this.isProduction && environment.env === 'prd') {
      this.addGAScript(environment.googleAnalyticsGeral);
      this.addGAScript(googleAnalyticsCode);
      this.addGAScript(oldGoogleAnalyticsCode);
    }
    if (!environment.env.includes('local')) {
      this.addGAScript(environment.oldGoogleAnalyticsGeral);
    }
  }

  addNewGAScripts() {

    //     <!-- Google Tag Manager -->
    // <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    // })(window,document,'script','dataLayer','GTM-5988GR34');</script>
    // <!-- End Google Tag Manager -->

    const gtagScript: HTMLScriptElement = document.createElement('script');

    gtagScript.innerHTML = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

  })(window,document,'script','dataLayer','GTM-5988GR34');`
    document.head.prepend(gtagScript);


    // <!-- Google Tag Manager (noscript) -->
    // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5988GR34"
    // height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    // <!-- End Google Tag Manager (noscript) -->


    const gtagScriptBody = document.createElement('noscript');

    gtagScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5988GR34"
    // height="0" width="0" style="display:none;visibility:hidden"></iframe`
    document.body.prepend(gtagScriptBody);


  }

  addGAScript(googleAnalyticsCode) {
    if (googleAnalyticsCode) {
      const gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsCode;
      document.head.prepend(gtagScript);
      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', googleAnalyticsCode, { send_page_view: false });
    }
  }

  addPagePathToAnalytics(path) {
    if (this.isProduction && environment.env === 'prd') {
      gtag('event', 'page_view', {
        page_path: path
      });
    }
  }

}
